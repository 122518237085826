<script>
import { defineComponent } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Popover } from 'element-ui'

export default defineComponent({
  components: {
    Popover,
  },
  setup() {
    return {

    }
  },
})
</script>

<template>
  <Popover
    trigger="hover"
    placement="bottom-start"
    popper-class="xw-popover"
    v-bind="$attrs"
  >
    <slot name="content"></slot>
    <div slot="reference">
      <slot name="reference"></slot>
    </div>
  </Popover>
</template>
<style lang="scss">
.el-popover.xw-popover{
  background: rgba(0,11,27,0.7);
  color: #fff;
}
.el-popper[x-placement^=bottom].xw-popover .popper__arrow, .el-popper[x-placement^=bottom].xw-popover .popper__arrow::after {
  border-bottom-color: rgba(0,11,27,0.4);
}
.el-popper[x-placement^=top].xw-popover .popper__arrow, .el-popper[x-placement^=top].xw-popover .popper__arrow::after {
  border-top-color: rgba(0,11,27,0.4);
}
.el-popper[x-placement^=left].xw-popover .popper__arrow, .el-popper[x-placement^=left].xw-popover .popper__arrow::after {
  border-left-color: rgba(0,11,27,0.4);
}
.el-popper[x-placement^=right].xw-popover .popper__arrow, .el-popper[x-placement^=right].xw-popover .popper__arrow::after {
  border-right-color: rgba(0,11,27,0.4);
}
</style>
